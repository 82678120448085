import request from '@/utils/request'


//获取用户信息
export function getUserInfo(params) {
    return request.get('/api-user/users/current', params)
}

//获取用户列表
export function getUserList(params) {
    return request.get('/api-user/users', params)
}

//新增或修改用户
export function userAddOrUpdate(params) {
    return request.post('/api-user/users/saveOrUpdate', params)
}

//获取用户详情
export function getUser(id) {
    return request.get('/api-user/users/' + id)
}

//批量删除用户
export function userRemove(params) {
    return request.remove('/api-user/users/deleteBatch', params)
}

//个人中心 更新信息
export function userUpdate(params) {
    return request.put('/api-user/users/update-user-info', params)
}

//个人中心 修改手机号发送短信验证码
export function smsChangeMobileSend(mobile) {
    return request.get('/api-uaa/validata/sms/change-mobile/' + mobile)
}

//个人中心 修改手机号
export function userChangeMobile(params) {
    return request.put('/api-user/users/update-mobile', params)
}

//个人中心 解绑微信
export function userUnbindWx(userId) {
    return request.put('/api-uaa/acl/wx/unbind-wx-ma/' + userId)
}

//个人中心 绑定微信前获取uuid
export function getUuid(userId) {
    return request.get('/api-uaa/acl/wx/get-bind-wx-ma-uuid/' + userId)
}

//个人中心 检查uuid状态
export function checkUuid(params) {
    return request.get('/api-uaa/acl/wx/check_wx_bind_uuid', params)
}